<script lang="ts" setup>
import {
  useCodeClient,
  type ImplicitFlowErrorResponse,
  type ImplicitFlowSuccessResponse
} from 'vue3-google-signin';
import useToast from '@/composables/helpers/useToast';

interface Props {
  onSuccess: (response: ImplicitFlowSuccessResponse) => Promise<void>;
  onError?: (errorResponse: ImplicitFlowErrorResponse) => Promise<void>;
  disabled?: boolean;
  uxMode?: 'popup' | 'redirect';
}

const toast = useToast();

const props = withDefaults(defineProps<Props>(), {
  uxMode: 'popup'
});

const loading = ref(false);

const handleOnSuccess = async (response: ImplicitFlowSuccessResponse) => {
  if (loading.value) {
    return;
  }

  if (props.onSuccess && typeof props.onSuccess === 'function') {
    loading.value = true;

    await props.onSuccess(response);

    loading.value = false;
  }
};

const handleOnError = (errorResponse: ImplicitFlowErrorResponse) => {
  if (props.onError && typeof props.onError === 'function') {
    props.onError(errorResponse);
  } else {
    toast.error(errorResponse.error_description || 'Something went wrong');
  }
};

const { isReady, login } = useCodeClient({
  onSuccess: handleOnSuccess,
  onError: handleOnError,
  ux_mode: props.uxMode
});

const buttonDisabled = computed(() => {
  return props.disabled || loading.value || !isReady.value;
});
</script>

<template>
  <button
    class="w-full h-[42px] flex justify-center items-center bg-white rounded-[5px] border border-brand-gray px-3 disabled:opacity-60"
    :disabled="buttonDisabled"
    @click="login"
  >
    <NuxtIcon name="google-icon" filled class="mr-2" />
    <span class="text-brand-dark-gray text-base">
      <slot>Sign in with Google</slot>
    </span>
  </button>
</template>
<style lang="scss" scoped>
:deep(.nuxt-icon svg) {
  @apply h-6 w-6 mb-0;
}
</style>
